// extracted by mini-css-extract-plugin
export var albumCover = "heroalbum-module--albumCover--a62f4";
export var albumDetails = "heroalbum-module--albumDetails--5eade";
export var albumSubhead = "heroalbum-module--albumSubhead--4e540";
export var albumTitle = "heroalbum-module--albumTitle--69b8d";
export var closeButton = "heroalbum-module--closeButton--abc67";
export var fullImage = "heroalbum-module--fullImage--f3932";
export var heroAlbumWrapper = "heroalbum-module--heroAlbumWrapper--ce3dc";
export var imageOverlay = "heroalbum-module--imageOverlay--85587";
export var imageWrapper = "heroalbum-module--imageWrapper--98c5b";
export var listenButton = "heroalbum-module--listenButton--7112c";
export var overlay = "heroalbum-module--overlay--95e0b";
export var overlayContent = "heroalbum-module--overlayContent--bb8e2";
export var overlayIcon = "heroalbum-module--overlayIcon--746a3";